/**
 * Loop through a map check if breakpoint exists in grid, else use px/rem value
 *
 * @params $breakpoint
 * @requires respond-up, $grid-breakpoints
 */
@mixin respond-up($breakpoint)
{
  @if map-has-key($grid-breakpoints, $breakpoint) {
    @include media-breakpoint-up($breakpoint) {
      @content;
    }
  }
  @else {
    @media (min-width: $breakpoint) {
      @content;
    }
  }
}

@mixin respond-down($breakpoint)
{
  @if map-has-key($grid-breakpoints, $breakpoint) {
    @include media-breakpoint-down($breakpoint) {
      @content;
    }
  }
  @else {
    @media (max-width: $breakpoint) {
      @content;
    }
  }
}

/**
 * Loop through a map and output each key/value as CSS property/value
 *
 * @mixin parse-font-stack($map)
 * @params $map
 * @requires null
 */
@mixin parse-font-stack($map)
{
  @each $prop, $value in $map {
    #{$prop}: #{$value};
  }
}

@mixin font-stack($font-stack)
{
  $map: map-get($font-stacks, $font-stack);
  @include parse-font-stack($map);
}

/**
 * Loop through a map and output each key/value as CSS property/value, while also converting to REM
 *
 * @mixin parse-font-size($map)
 * @params $map
 * @requires null
 * @link https://www.liquidlight.co.uk/blog/article/responsive-typography-in-scss/
 */
@mixin parse-font-size($map)
{
  @each $prop, $value in $map {
    #{$prop}: #{$value};
    @if $prop == 'font-size' {
      #{$prop}: calc-rem($value);
    }
  }
}

@mixin font-size($font-size)
{
  $map: map-get($font-sizes, $font-size);
  $base: map-get($map, base);
  @include parse-font-size($base);
  $map-breakpoints: map-remove($map, base);
  @each $breakpoint, $attr in $map-breakpoints {
    @include media-breakpoint-up($breakpoint) {
      @include parse-font-size($attr);
    }
  }
}

@mixin font-smoothing($enable: true)
{
  @if ($enable == false) {
    -webkit-font-smoothing: subpixel-antialiased;
  } @else {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
}
