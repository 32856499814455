// Search form
.search-form {
	@extend .form-inline;
}
.search-form label {
	font-weight: normal;
	@extend .form-group;
}
.search-form .search-field {
	@extend .form-control;
}
.search-form .search-submit {
	@extend .btn;
	@extend .btn-secondary;
}


.form_wrapper {

	.field {
		background: get-color(grey, base);
		width: 100%;
		height: 53px;
		border-radius: 10px;
		border: none;
		padding-left: 10px;
		@include font-size(base);
		color: get-color(grey, text);
		@include font-stack(primary);
	}
	::-webkit-input-placeholder { /* Chrome/Opera/Safari */
		color: get-color(grey, text);
		@include font-stack(primary);
		@include font-size(base);
	}
	::-moz-placeholder { /* Firefox 19+ */
		color: get-color(grey, text);
		@include font-stack(primary);
		@include font-size(base);
	}
	:-ms-input-placeholder { /* IE 10+ */
		color: get-color(grey, text);
		@include font-stack(primary);
		@include font-size(base);
	}
	:-moz-placeholder { /* Firefox 18- */
		color: get-color(grey, text);
		@include font-stack(primary);
		@include font-size(base);
	}
}

.form-table {
    margin: 20px;
}

.form-radio,
.form-group {
  position: relative;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.form-inline > .form-group,
.form-inline > .btn {
  display: inline-block;
  margin-bottom: 0;
}

.form-help {
  margin-top: 0.125rem;
  margin-left: 0.125rem;
  color: #b3b3b3;
  font-size: 0.8rem;
}
.checkbox .form-help, .form-radio .form-help, .form-group .form-help {
  position: absolute;
  width: 100%;
}
.checkbox .form-help {
  position: relative;
  margin-bottom: 1rem;
}
.form-radio .form-help {
  padding-top: 0.25rem;
  margin-top: -1rem;
}

.form-group input {
  height: 1.9rem;
}
.form-group textarea {
  resize: none;
}
.form-group select {
  width: 100%;
  font-size: 1rem;
  height: 1.6rem;
  padding: 0.125rem 0.125rem 0.0625rem;
  background: none;
  border: none;
  line-height: 1.6;
  box-shadow: none;
}
.form-group .control-label {
  position: absolute;
  top: 0.25rem;
  pointer-events: none;
  padding-left: 0.125rem;
  z-index: 1;
  color: #b3b3b3;
  font-size: 12px;
  font-weight: normal;
  -webkit-transition: all 0.28s ease;
  transition: all 0.28s ease;
}

.form-group label {
    display: inline-block;
    margin-bottom: 0.5rem;
    font-size: 0.8rem;
    color: gray;
    top: -1rem;
    left: 0;
}

.form-group-select label {
    display: inline-block;
    margin-bottom: 0;
    font-size: 0.8rem;
    color: gray;
    left: 0;
    margin-top: 10px;
}

.form-group .bar {
  position: relative;
  border-bottom: 0.0625rem solid #ccc;
  display: block;
}
.form-group .bar::before {
  content: '';
  height: 0.125rem;
  width: 0;
  left: 50%;
  bottom: -0.0625rem;
  position: absolute;
  background: #00999D;
  -webkit-transition: left 0.28s ease, width 0.28s ease;
  transition: left 0.28s ease, width 0.28s ease;
  z-index: 2;
}
.form-group input,
.form-group password,
.form-group textarea {
  display: block;
  background: none;
  padding: 0.125rem 0.125rem 0.0625rem;
  font-size: 1rem;
  border-width: 0;
  border-color: transparent;
  line-height: 1.9;
  width: 100%;
  color: transparent;
  -webkit-transition: all 0.28s ease;
  transition: all 0.28s ease;
  box-shadow: none;
}
.form-group input[type="file"] {
  line-height: 1;
}
.form-group input[type="file"] ~ .bar {
  display: none;
}
.form-group select,
.form-group input:focus,
.form-group input:valid,
.form-group input.form-file,
.form-group input.has-value,
.form-group textarea:focus,
.form-group textarea:valid,
.form-group textarea.form-file,
.form-group textarea.has-value {
  color: #333;
}
.form-group select ~ .control-label,
.form-group input:focus ~ .control-label,
.form-group input:valid ~ .control-label,
.form-group input.form-file ~ .control-label,
.form-group input.has-value ~ .control-label,
.form-group textarea:focus ~ .control-label,
.form-group textarea:valid ~ .control-label,
.form-group textarea.form-file ~ .control-label,
.form-group textarea.has-value ~ .control-label {
  font-size: 0.8rem;
  color: gray;
  top: -1rem;
  left: 0;
}
.form-group select:focus,
.form-group input:focus,
.form-group textarea:focus {
  outline: none;
}
.form-group select:focus ~ .control-label,
.form-group input:focus ~ .control-label,
.form-group textarea:focus ~ .control-label {
  color: #00999D;
}
.form-group select:focus ~ .bar::before,
.form-group input:focus ~ .bar::before,
.form-group textarea:focus ~ .bar::before {
  width: 100%;
  left: 0;
}

.selectize-input {
    border: none;
    -webkit-box-shadow: inset 0 0px 0px rgba(0, 0, 0, 0.1);
    box-shadow: inset 0 0px 0px rgba(0, 0, 0, 0.1);
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 0px;
    border-bottom: 1px solid #ccc !important;
}

h5.form-section {
    color: #00999D;
    text-transform: uppercase;
    font-size: 16px;
    font-weight: bold;
    margin-top: 30px;
    margin-bottom: 20px;
}

h5.block-section {
    color: #00999D;
    text-transform: uppercase;
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 20px;
}

.form-helper {
    font-size: 12px;
    padding-top: 10px;
    margin-bottom: 25px;

    span {
        font-weight: bold;
    }

}

.sectionTitle {
    font-weight: 300;
    margin-top: 50px;
    margin-bottom: 0px;
    font-size: 23px;
    color: #00999D;
    display: inline-block;
}

form.form {
    display: contents;
}

.is-invalid {
    color: red;
}

i.bar.is-invalid {
    border-bottom: 1px solid red;
}
