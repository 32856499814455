// Adding Modal Styles here

.modal-backdrop.show {
	opacity: 1;
	background-color: #1A1A1A;
}

#notification_modal {

	.modal-body {
		padding: 3rem;
	}

	.close {
		text-shadow: none;
	}

	.modal-dialog {
		max-width: 680px!important;
		margin: 1.75rem auto;
	}

	p {
		width: 70%;
		margin: auto;
	}
}

// Tabs Styling

.nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {
	color: #747474;
	background-color: #efefef;
	border-color: transparent;
	border-radius: 10px 10px 0 0;
}

.nav-tabs .nav-link {
	border: transparent;
	border-top-left-radius: 0.25rem;
	border-top-right-radius: 0.25rem;
	color: #38a7a8;
}

.nav-tabs {
	border-bottom: transparent;
}

.tab-content {
	border-radius: 0 10px 10px 10px;

	input.field {
		border: 0;
		width: 100%;
		height: 40px;
		padding-left: 15px;
	}

	textarea {
		border: 0;
		width: 100%;
		height: 90px;
		padding-left: 15px;
	}
}

[data-toggle="collapse"] .fa:before {
	content: "\f139";
}

[data-toggle="collapse"].collapsed .fa:before {
	content: "\f13a";
}

#accordion {

	.card-header {
		padding: 0;
		margin-bottom: 0;
		background-color: rgba(0, 0, 0, 0.03);
		border-bottom: transparent;

		button.btn.btn-link {
			color: #707070;

			i {
				margin-right: 20px;
			}
		}
	}

	.card {
		position: relative;
		display: -webkit-box;
		display: flex;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		flex-direction: column;
		min-width: 0;
		word-wrap: break-word;
		background-color: #fff;
		background-clip: border-box;
		border: transparent;
		border-radius: 0.25rem;
		margin-bottom: 30px;
	}
}


// Pills

.nav-pills .nav-link.active, .nav-pills .show > .nav-link {
    color: #fff;
    background-color: #00999D;
}

.nav-pills .nav-link {
    border-radius: 0.25rem;
    color: #00999D;
}


// Competition Pages

p.disclaimer {
    font-size: 12px;
    color: #999;
    font-style: italic;
}

.competition-icon {
    font-size: 60px;
    width: 100%;
    margin-bottom: 20px;
    color: #00999D;
}


.terms {

    ul {
        padding-left: 20px;
    }

    li {
        font-size: 14px;
        text-align: left;
    }

}



// Auth Pages

.id-errors {
    margin-top: 20px;
}

li.error_msg {
    margin-left: 20px;
    text-align: center;
    list-style: none;
}
