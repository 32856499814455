.gutter-sizer { width: 4%; }
.grid-item,
.grid-sizer {
  width: 22%;
}

.offers {

    .gutter-sizer {
        width: 4%;
    }
    .grid-item,
    .grid-sizer {
        width: 42% !important;
    }

}

.cat_filter {

	.title {
		@include font-size(base);
	}

	button {
		background-color: transparent;
		display: block;
		width: 100%;
		border: none;
		font-size: 14px;
		color: #707070;
		text-align: left;
		margin-bottom: 20px;
		text-transform: capitalize;
		transition: color .3s;
		&:hover {
			color: get-color(brand);
		}

    }

    .link-button {
		background-color: transparent;
		display: block;
		width: 100%;
		border: none;
		font-size: 14px;
		color: #707070;
		text-align: left;
		margin-bottom: 20px;
		text-transform: capitalize;
		transition: color .3s;
		&:hover {
            color: get-color(brand);
            text-decoration: none;
		}

	}
}

.alpha_filter {
	button {
		background-color: transparent;
		border: none;
		font-size: 14px;
		color: #707070;
		text-align: left;
		text-transform: capitalize;
		transition: color .3s;
		&:hover {
			color: get-color(brand);
		}
	}
}

.is-checked {
	font-weight: bold;
}

@include respond-up(sm) {
	.mobileselectselected{
		display: none;
	}
}

@include respond-down(sm) {
	.mobileselectselected {
		background: get-color(grey);
		height: 40px;
		background-color: transparent;
		display: block;
		width: 100%;
		border: none;
		font-size: 14px;
		color: #707070;
		text-align: left;
		margin-bottom: -20px;
		text-transform: capitalize;
		transition: color .3s;
		text-align: center;
		position: relative;
		transition: height 0.3s ease;

		span {
			position: absolute;
			right: 10px;
		}
	}

	.mobileselect {
		height: 0;
		overflow: hidden;
		transition: height 0.3s ease;
		button {
			display: block;
			width: 100%;
			text-align: center;
		}
	}

	.openme {
		height: auto;
		transition: height 0.3s ease;
	}
}

@include respond-up(sm) {
	.mobileselectselectedagain{
		display: none;
	}
}

@include respond-down(sm) {
	.mobileselectselectedagain {
		background: get-color(grey);
		height: 40px;
		background-color: transparent;
		display: block;
		width: 100%;
		border: none;
		font-size: 14px;
		color: #707070;
		text-align: left;
		margin-bottom: -20px;
		text-transform: capitalize;
		transition: color .3s;
		text-align: center;
		position: relative;
		transition: height 0.3s ease;

		span {
			position: absolute;
			right: 10px;
		}
	}

	.mobileselectagain {
		height: 0;
		overflow: hidden;
		transition: height 0.3s ease;
		button {
			display: block;
			width: 100%;
			text-align: center;
		}
	}

	.openmeagain {
		height: auto;
		transition: height 0.3s ease;
	}
}

.img-fluid-temp {
    max-width: 160px;
    height: auto;
}

.color-shape {
    margin-bottom: 20px;
}

// Hover Animations

[class*=" imghvr-"] figcaption, [class^=imghvr-] figcaption {
    background-color: #13A7A8;
    color: #fff;
}

[class*=" imghvr-reveal-"]:before,
[class^=imghvr-reveal-]:before {
    background-color: #13A7A8;
}

[class*=" imghvr-shutter-in-"]:after,
[class*=" imghvr-shutter-in-"]:before,
[class^=imghvr-shutter-in-]:after,
[class^=imghvr-shutter-in-]:before {
    background: #13A7A8;
}

h3.merchant-hover {
    font-size: 16px;
    text-align: center;
    margin-top: 20px;
}
