.btn_stroke_white {
	padding: 12px 35px;
	font-size: 14px;
	color: #fff;
	border-radius: 10px;
    border:2px solid white;
	@include transition(0.4s);

	&:hover {
		background: white;
		color: get-color(primary);
		text-decoration: none;
	}
}

.btn_stroke_brand {
	padding: 12px 20px;
	font-size: 14px;
	color: get-color(brand);
	border-radius: 10px;
    border: 2px solid get-color(brand);
	@include transition(0.4s);

	&:hover {
		background: get-color(brand);
		color: white;
		text-decoration: none;
	}
}

.btn_solid_brand {
	padding: 12px 20px;
	font-size: 14px;
	color: white;
	border-radius: 10px;
	border: 2px solid get-color(brand);
    background: get-color(brand);
	@include transition(0.4s);
	svg path {
		@include transition(0.4s);
		fill: white;
	}

	&:hover {
		background: white;
		color: get-color(brand);
		text-decoration: none;
		border: 2px solid get-color(brand);

		svg path {
			fill: get-color(brand);
		}

	}
}

.btn_solid_white {
	padding: 12px 20px;
	font-size: 14px;
	color: get-color(grey, text);
	border-radius: 10px;
	border: 2px solid white;
    background: white;
	@include transition(0.4s);

	&:hover {
		background: white;
		color: #333;
		text-decoration: none;
		border: 2px solid white;
	}
}

.btn_text_white {
	font-size: 14px;
	color: #fff;
	@include transition(0.4s);
	&:hover {
		color: get-color(primary);
		text-decoration: none;
	}
}

.link_grey {
	color: get-color(grey, text);
	font-weight: 600;
	@include transition(0.4s);
	&:hover {
		color: get-color(brand);
		text-decoration: none;
	}
}

.link_light {
	color: #C3BEBE;
	font-weight: 400;
	@include transition(0.4s);
	&:hover {
		color: get-color(brand);
		text-decoration: none;
	}
}

.link_white {
	color: white;
	font-weight: 600;
	@include transition(0.4s);
	&:hover {
		color: get-color(brand);
		text-decoration: none;
	}
}

.btn_merchant_overlay {
    display: inline-block;
    font-weight: 400;
    text-align: center;
    vertical-align: middle;
    user-select: none;
    background-color: white;
	padding: 12px 35px;
	font-size: 14px;
	color: #13A7A8;
	border-radius: 10px;
    border:2px solid white;
    line-height: 1.5;
	@include transition(0.4s);

	&:hover {
		background: white;
		color: #13A7A8;
		text-decoration: none;
	}
}


.btn-help {
    color: #fff;
    font-size: 16px;
    margin: 0;
    padding: 0;
    margin-left: 1.5rem;

    &:hover {
        color: #fff;
		text-decoration: none;
    }
}

.btn-user {
    color: #fff;
    font-size: 20px;
    margin: 0;
    padding: 0;
    margin-left: 1.5rem;

    &:hover {
        color: #fff;
		text-decoration: none;
    }

}

.btn-start {
    border-radius: 10px !important;
}

.user-menu .dropdown-item {
    font-size: 12px;
    text-align: right;
}

.dropdown-divider {
    margin: 0.5rem !important;
}


.btn-social {
    color: #fff;
    background-color: #13A7A8;
    border-color: #13A7A8;
    margin-right: 20px;

    &:hover {
        color: #fff;
        background-color: rgb(14, 143, 143);
		text-decoration: none;
    }

}


.btn-offers {
    margin-left: auto;
    margin-right: auto;
    padding: 20px;
    width: 200px;
    background-color: grey;
    border-color: grey;

    &:hover {
        padding: 20px;
        color: grey;
        background-color: transparent;
        border-color: grey;
		text-decoration: none;
    }
}
