footer {
	ul {
		margin: 0;
		padding: 0;
		li {
			margin-left: 0;
			padding-left: 0;
			list-style: none;
			margin-bottom: 5px;

			a {
				font-size: 12px;
				color: #EFEFEF;
				@include transition(.4s)
				&:hover {
					color: get-color(brand);
					text-decoration: none;
				}
			}
		}
	}

	.app_icons {
		img {
			width: 30px;
		}
	}

	.line_links {
		border-top: 1px solid #707070;
		border-bottom: 1px solid #707070;
		padding-top: 10px;
		padding-bottom: 13px;
		a {
			color: #707070;
			font-size: 12px;
			&:hover {
				color: get-color(brand);
				text-decoration: none;
			}
		}
	}

	p {
		color: #707070;
		font-size: 12px;
		margin-bottom: 0;
	}
}

// With Mustard


#withMustard {
	text-align: center;
	margin: auto;

	a {
		width: 150px;
		position: relative;
		overflow: hidden;
		display: block;
		margin: auto;
		transition: 0.5s ease;
	}

	span {
		font-size: 12px;
		color: #fff;
		padding-left: 15px;
		position: absolute;
		left: -100;
		display: block;
		transition: 0.5s ease;
	}

	a:hover {
		text-decoration: none;
		span {
			left: 0;
			display: inline-block;
			position: relative;
		}
	}
}