.noScroll {
	overflow: hidden;
}

.fs_nav {
	height: 100%;
	background: #1A1A1A;
	z-index: 9999;
	width: 100vw;
	position: fixed;
	left: -100vw;
	transition: left 0.6s;

	.close {
		position: absolute;
		top: 20px;
		right: 20px;

		&:hover {
			cursor: pointer;
		}

		svg {
			width: 20px;
		}
	}

	.logo {
		position: absolute;
		top: 20px;
		left: 20px;
	}

	.sm_icons {
		position: absolute;
		bottom: 20px;
		left: 20px;

		svg {
			height: 20px!important;
			width: 20px!important;

			&:hover {
				path {
					fill: white;
				}
			}
		}

		@include respond-down(sm) {
			display: block;
			margin-bottom: 10px;

			svg {
				height: 20px!important;
				width: 20px!important;
			}
		}
	}

	.details {
		position: absolute;
		bottom: 20px;
		right: 20px;

		@include respond-down(sm) {
			a {
				font-size: 14px!important;
			}
		}
	}

	a {
		color: #707070;
		@include font-size(kilo);
		padding-left: 20px;
		padding-right: 20px;
		&:hover {
			font-weight: 700;
			color: #fff;
			text-decoration: none;
		}

		@include respond-down(sm) {
			display: block;
			margin-bottom: 10px;
		}
	}
}

a:hover {
	cursor: pointer;
}

.menu svg {
	width: 20px;
	z-index: 20;
}

.opennav {
	left: 0;
	transition: left 0.6s;
	position: fixed;
	z-index: 100;
	z-index: 99999999999;
}

nav {
	position: fixed;
	width: 100%;
	padding-top: 35px;
	padding-bottom: 35px;
	z-index: 20;
	@include transition(0.4s);
	z-index: 9999!important;
}

.transparentbar {
	background: transparent;
	transition: background 0.6s;
	@include transition(0.4s);
	z-index: 999;
}

.darkbar {
	background: get-color(brand);
	@include transition(0.4s);
	z-index: 999;
}

header {
	height: 100vh;
	background: rgb(0,158,177);
	background: linear-gradient(#009eb1 0%, #20b6b4 55.17%, #20b6b4 100%);
	@include transition(0.4s);
	z-index: 999;
	transition: 0.4s;
}

.header-grey {
	height: 100vh;
	background: #353536 !important;
	@include transition(0.4s);
	z-index: 999;
}

.b20 {
	border: 20px solid #efefef;
	@include transition(0.4s);
}

.b0 {
	border: 0px solid #efefef;
	@include transition(0.4s);
}

header.subpage {
	height: 60vh;
	background: rgb(0,158,177);
    background: linear-gradient(#009eb1 0%, #20b6b4 55.17%, #20b6b4 100%);
    min-height: 500px;

}

header.subpage-merchant {
	height: 60vh;
	position: relative;
    overflow: hidden;
	min-height: 500px;

	.store_overlay {
		z-index: 1;
		position: absolute;
		width: 100%;
		height: 100vh;
		display: block;
		opacity: 0.6;
	}
}

header .container {
	z-index: 20;
	position: relative;
}

header.no-border {
	box-shadow: none;

}
.arrow_down {
	position: absolute;
	left: 49vw;
	right: 0;
	bottom: 40px;
}

#canvas {
	position: absolute;
	width: 100vw;
	height: 100vh;
	z-index: 10;

	svg {
		width: 100vw;
		height: 100vh;
	}
}

.merchant_bar {
	z-index: 999;
}

#notification_bar {

	height: 50px;
	position: relative;
	z-index: 999999;
    background: #d83737;

	.container {
		background: #d83737;
		height: 50px;
		padding: 15px;
		position: fixed;
		z-index: 999999;
		max-width: 100%;
		padding-left: 10%;
        padding-right: 10%;
        overflow: hidden;
    }

    @include respond-down(sm) {

        height: 120px;
        position: relative;
        z-index: 999999;
        background: #d83737;

        .container {
            background: #d83737;
            height: 120px;
            padding: 15px;
            position: fixed;
            z-index: 999999;
            max-width: 100%;
            padding-left: 10%;
            padding-right: 10%;
            overflow: hidden;
        }

    }

	.close_notification_bar {
		position: absolute;
		right: 0;
		color: #fff;
		line-height: 0;
		font-size: 20px;
	}

	.icon {
		color: #fff;
		font-size: 20px;
		margin-right: 30px;
		line-height: 0;
	}

	p {
		margin: 0;
		padding: 0;
	}
}


// #notification_bar .container {
//     background: #d83737;
//     height: 100px;
//     padding: 15px;
//     position: relative;
//     z-index: 999999;
//     max-width: 100%;
//     padding-left: 10%;
//     padding-right: 10%;
// }




.dismissme {
	display: none!important;
}

.color_red {
	background: #d83737;

	p {
		color: #fff;
	}
}


h1.header-class {
    font-size: 16px;
}

.grantsucks {
    display: none;
}
