
.ball {
	background: #fff;
	width: 20px;
	height: 20px;
	border-radius: 50%;
	display: block;
	position: relative;
	@include transition(0.4s);

	@include respond-down(sm) {
		position: absolute;
	}
}

.line_marker_vert {
	display: block;
	position: absolute;
	height: 100%;
	width: 2px;
	background: #fff;
	margin-top: 8px;
	margin-left: 23px;
}

.line_marker {
	display: block;
	position: absolute;
	height: 2px;
	width: 100%;
	background: #fff;
	margin-top: 8px;
}

img.phone {
	max-width: 300px;
	position: absolute;
}

img.monitor {
    max-width: 610px;
    position: absolute;
}


.block_item {

	.overlay {
		top: 0;
		left: 0;
		padding-top: 40%;
		opacity: 0;
		transition: opacity 0.4s;
	}

	&:hover {
		.overlay {
			opacity: 1;
		}
	}

	.merchant_name {
		height: 160px;
	}

}

.block_large {

	.overlay {
		top: 0;
		left: 0;
		padding-top: 45%;
		opacity: 0;
		transition: opacity 0.4s;
	}

	&:hover {
		.overlay {
			opacity: 1;
		}
	}

}

.block_thing {

	position: absolute;
	right: 20px;
	top: -300px;
	z-index: 20;

	@include respond-down(sm) {
		position: relative;
		top: 0;
		text-align: center;
		margin: auto;
		width: 80%;
		right: 0;
		margin-top: -120px;
	}

	.box {
		background: white;
		width: 300px;
		height: 300px;
		padding: 30px;
		margin-bottom: 15px;

		@include respond-down(sm) {
			width: 150px;
			height: 150px;
			padding: 30px;
			margin-bottom: 15px;
			margin: 10px auto;

		}
	}

}

.expander {
	text-align: center;

	.number {
		font-size: 40px;
		text-align: center;
		width: 80px;
		height: 80px;
		border-radius: 50%;
		border: 2px solid white;
		margin: auto;
		padding-top: 10px;
		color: #fff;
	}

	h5 {
		margin-top: 1.2rem;
	}
}

.addcheese_001 {
	@include transition(0.4s);
	top: 0;
	.shadow-sm {
		@include transition(0.4s);
	}

}

.addcheese_001:hover {
	top: -3px;

	.shadow-sm {
		box-shadow: 0 0.625rem 0.55rem rgba(0, 0, 0, 0.085) !important;
	}
}

.addcheese_002 {
	@include transition(0.4s);
	&:hover {
		cursor: pointer;

		.ball {
			background: get-color(brand);
			width: 30px;
			height: 30px;
			border-radius: 50%;
			display: block;
			position: relative;
			border: 5px solid white;
			margin-top: -5px;
			margin-bottom: -5px;

			@include respond-down(sm) {
				position: absolute;
				top: 20px;
				margin-left: -7px;
				margin-top: -25px;
			}
		}
	}

}

#exampleModal .modal-dialog {
	max-width: 740px!important;
	margin: 1.75rem auto;
	z-index: 99999!important;

	button.closeModal {
		background: transparent;
		border: none;
		color: white;
		font-size: 28px;
	}

	.modal-content {
		position: relative;
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-ms-flex-direction: column;
		flex-direction: column;
		width: 100%;
		pointer-events: auto;
		background-color: transparent;
		background-clip: padding-box;
		border: none;
		border-radius: 0.3rem;
		outline: 0;
	}
}

.modal-open .modal {
	z-index: 999999!important;
}

.block_clickable:hover {
	cursor: pointer;

	p {
		text-decoration: none;
		color: #fff;
	}
}


.block_details_help {
	padding-left: 0;
	margin-left: -0px;
	list-style: none;

	li {
		padding-left: 0;
		margin-left: -0px;
		list-style: none;

		i {
			margin-right: 20px;
		}
	}
}

.tick_boxes {

	margin-top: 4rem;

	i.fas.fa-check {
		font-size: 30px;
		color: #6f6f6f;
	}

	p {
		font-size: 16px;
		margin-top: 20px;
		color: #6f6f6f;
	}
}


.card-merchant {
    min-height: 230px;
}

.logo-border {
    border: 1px solid #efefef;
}

.bg_green {
    background-color: #13A7A8;
}

.text_white {
    color: #fff;
}

.captcha-offset {
    margin-top: 20px;
}
