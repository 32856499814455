@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,600,700,900&display=swap');

// Reset all fonts

h1, h2, h3, h4, h5, h6, p, a, li, span {
	font-family: 'Open Sans', sans-serif;
}

.mh-400 {
	min-height: 450px;
}

// Global Color Overides

.text-primary {
	color: get-color(primary)!important;
}

.text_grey {
	color: get-color(grey, text)!important;
}

.text_lgrey {
	color: get-color(grey, lgrey)!important;
}

.text-brand {
	color: get-color(brand, base)!important;

}

// Global Weights

.text-reg {
	@include font-stack(primary);
}

.text-bold {
	@include font-stack(primary-bold);
}

.text-semibold {
	@include font-stack(primary-medium);
}

// Global Fonts

h1 {
	@include font-size(mega);
}

h2 {
	@include font-size(giga);
	span {
		@include font-stack(primary-xbold);
	}
}

h3 {
	@include font-size(kilo);
	span {
		@include font-stack(primary-bold);
	}
}

h4 {
	@include font-size(deca);
	@include font-stack(primary-bold);
}

h2.text-xl {
	@include font-size(textxl);
	span {
		font-weight: 600!important;
	}
}

h5 {
	@include font-size(base);
}

p {
	@include font-size(deci);
}

.deca {
	@include font-size(deca);
}

.deci {
	font-size: 12px!important;
}

.text-hecto {
	@include font-size(hecto);
}

.small-h1 {
    font-size: 16px;
}

a {
    color: #00999D;
    text-decoration: none;
    background-color: transparent;

    &:hover {
        color: rgb(0, 131, 136);
    }
}



// bg

.bg_grey {
	background: get-color(grey);
}

.bg_brand {
	background: get-color(brand);
}

.bg_dark {
	background: #1A1A1A;
}

.bg_gradient_grey {
	background: rgb(128,127,127);
	background: linear-gradient(180deg, rgba(128,127,127,1) 0%, rgba(95,94,94,1) 100%);
}

.mt-20 {
    margin-top: 20px;
}
